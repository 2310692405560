import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRetrocessions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/retrocession/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRetrocession(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/retrocession`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addRetrocession(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/retrocession", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addMultiRetrocession(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/retrocession/multi", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteRetrocession(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/retrocession", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClientBanks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/client/client_bank/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateCurrencyRate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/currency_rate", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getRequestTemplate(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/retrocession/template", {responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportRetrocession(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/retrocession/export", {'params' : data, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

  },
};
