import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useRetrocessionList() {

    //User toast
    const toast = useToast()

    const refRetrocessionListTable = ref(null)

    const tableColumns = [
        // {key: 'year'},
        // {key: 'quarter'},
        { key: 'client_id', stickyColumn: true, label: 'JVSakk A/C No.', sortable: true },
        { key: 'client_name', label: 'Client Name', sortable: true },
        { key: 'report_name', label: 'Report Name', sortable: true },
        { key: 'relationship_manager_name', label: 'Relationship Manager', sortable: true },
        { key: 'client_bank_bank_name', label: 'Custodian Bank', sortable: true },
        { key: 'client_bank_account_number', label: 'Account No.', sortable: true },
        { key: 'amount', label: 'Amount', sortable: true },
        // { key: 'hkd', label: 'Total HKD' },
        { key: 'usd', label: 'Equivilant USD' },
        { key: 'actions' },
    ]

    const tableFilterColumns = [

        // {key: 'year'},
        // {key: 'quarter'},
        { key: 'client.id', stickyColumn: true, label: 'JVSakk A/C No.' },
        { key: 'client.name_en', label: 'Client Name' },
        { key: 'client.name_cn', label: 'Chinese Name' },
        { key: 'client.relationship_manager.name', label: 'Relationship Manager' },
        { key: 'bank.name', label: 'Custodian Bank' },
        { key: 'account_number', label: 'Account No.' },
        { key: 'status', label: 'AccountStatus' },
    ]

    const yearFilter = ref(new Date().getFullYear())
    const quarterFilter = ref(Math.floor((new Date().getMonth() + 3) / 3));
    const searchTypeFilter = ref('quarter')
    const loading = ref(false)

    const perPage = ref(10)
    const totalRetrocession = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const currencyInfo = ref('')
    const retrocessionsInfo = ref([]);
    const sortBy = ref('id')
    const isSortDirDesc = ref(true);
    const triggerFetch = ref(false);


    const dataMeta = computed(() => {
        const localItemsCount = refRetrocessionListTable.value ? refRetrocessionListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRetrocession.value,
        }
    })

    const refetchData = () => {
        refRetrocessionListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, yearFilter, quarterFilter, searchTypeFilter, triggerFetch], () => {
        refetchData()
    })

    const fetchRetrocession = (ctx, callback) => {
        loading.value = true
        store.dispatch('app-retrocession/fetchRetrocessions', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            year: yearFilter.value,
            quarter: quarterFilter.value,
            search_type: searchTypeFilter.value
        })
            .then(response => {
                const { retrocessions, total, currency_rate } = response.data
                currencyInfo.value = currency_rate;
                retrocessionsInfo.value = [...response.data.retrocessions]
                callback(retrocessions)
                totalRetrocession.value = total
                loading.value = false

            })
            .catch((error) => {
                console.log(error)
                loading.value = false
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching retrocession list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })
            })
    }

    return {
        loading,
        triggerFetch,
        retrocessionsInfo,
        fetchRetrocession,
        currencyInfo,
        tableColumns,
        tableFilterColumns,
        perPage,
        currentPage,
        totalRetrocession,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refRetrocessionListTable,
        ability,
        refetchData,
        yearFilter,
        quarterFilter,
        searchTypeFilter,
    }

}